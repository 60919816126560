import * as React from "react";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";

// markup
const NotFoundPage = () => {
  return (
    <main
      style={{
        fontFamily:
          '-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>404 Not Found</title>
        <meta name="description" content="TODO." />
      </Helmet>
      <body>
        <h1>404 Not Found.</h1>
        <p>
          <Link to="/">Go home</Link>.
        </p>
      </body>
    </main>
  );
};

export default NotFoundPage;
